import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/AccountPage.css";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Spinner } from "react-bootstrap";

const UserInfo = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editableData, setEditableData] = useState({});

  const removeAccentsAndUppercase = (str) =>
    str
      ? str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
      : "";

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");

    if (!storedUserId) {
      console.error("[ERROR] Aucun ID utilisateur trouvé dans le stockage local.");
      setError("Aucun ID utilisateur trouvé.");
      setLoading(false);
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://dev.mysheduloom.fr/api/getUserInfo?userId=${storedUserId}`
        );
        if (!response.ok) {
          throw new Error(`Erreur : ${response.statusText}`);
        }

        const result = await response.json();
        const user = result[0];

        const formattedAddress = removeAccentsAndUppercase(user.address);

        setUserData({ ...user, address: formattedAddress });

        setEditableData({
          address: formattedAddress,
          phone: user.phone,
          nom: user.nom,
          prenom: user.prenom,
          email: user.email,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (field, value) => {
    setEditableData({
      ...editableData,
      [field]: field === "address" ? removeAccentsAndUppercase(value) : value,
    });
  };

  const handleCancel = () => {
    setEditableData({
      address: userData.address,
      phone: userData.phone,
      nom: userData.nom,
      prenom: userData.prenom,
      email: userData.email,
    });
    setIsEditing(false);
  };

  const toggleEditing = async () => {
    if (isEditing) {
      setIsSubmitting(true);
      try {
        const { role, ...dataToUpdate } = editableData;
        const response = await fetch(
          "https://dev.mysheduloom.fr/api/updateUserInfo",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              userId: localStorage.getItem("userId"),
              ...dataToUpdate,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la mise à jour des données.");
        }

        const result = await response.json();

        if (result.success) {
          setUserData({ ...userData, ...dataToUpdate });
          alert("Vos informations ont été mises à jour avec succès.");
        } else {
          alert("Une erreur est survenue : " + result.message);
        }
      } catch (error) {
        console.error("[ERROR]", error.message);
        alert("Une erreur est survenue lors de l'envoi des modifications.");
      } finally {
        setIsSubmitting(false);
      }
    }
    setIsEditing(!isEditing);
  };

  const displayValue = (value) => (value !== null && value !== undefined ? value : "N/A");

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
        <span className="ms-3">Chargement des données...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="alert alert-danger">
          <strong>Erreur :</strong> {error}
        </div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="alert alert-warning">Aucune donnée utilisateur trouvée.</div>
      </div>
    );
  }

  return (
    <div className="global-div-button">
      <div className="profile-avatar-buttons">
        <button
          className="btn btn-primary edit-button"
          onClick={toggleEditing}
          disabled={isSubmitting}
        >
          {isEditing ? (isSubmitting ? "Enregistrement..." : "Valider") : "Éditer"}
        </button>
      </div>
      <div className="profile-avatar-buttons-cancel">
        {isEditing && (
          <button
            className="btn btn-secondary ms-2"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Annuler
          </button>
        )}
      </div>

      <div className="mx-auto my-4" style={{ maxWidth: "600px", padding: "20px" }}>
        <div className="user-info">
          <div style={{ marginBottom: "10px" }}>
            <strong>Nom de l'entreprise :</strong> <span>{displayValue(userData.companyname)}</span>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Numéro de contrat :</strong> <span>{displayValue(userData.contractnumber)}</span>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Nom :</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                value={editableData.nom || ""}
                onChange={(e) => handleInputChange("nom", e.target.value)}
                className="form-control d-inline w-75"
              />
            ) : (
              <span>{displayValue(userData.nom)}</span>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Prénom :</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                value={editableData.prenom || ""}
                onChange={(e) => handleInputChange("prenom", e.target.value)}
                className="form-control d-inline w-75"
              />
            ) : (
              <span>{displayValue(userData.prenom)}</span>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Adresse :</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                value={editableData.address || ""}
                onChange={(e) => handleInputChange("address", e.target.value)}
                className="form-control d-inline w-75"
              />
            ) : (
              <span>{displayValue(userData.address)}</span>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Téléphone :</strong>{" "}
            {isEditing ? (
              <ReactPhoneInput
                country={"fr"}
                value={editableData.phone || ""}
                onChange={(value) => handleInputChange("phone", value)}
                containerClass="react-tel-input"
                inputClass="form-control"
                buttonClass="btn btn-outline-secondary"
                dropdownClass="react-tel-dropdown"
                preferredCountries={["fr", "us", "gb"]}
                enableSearch={true}
              />
            ) : (
              <span>{displayValue(userData.phone)}</span>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Email :</strong>{" "}
            {isEditing ? (
              <input
                type="email"
                value={editableData.email || ""}
                onChange={(e) => handleInputChange("email", e.target.value)}
                className="form-control d-inline w-75"
              />
            ) : (
              <span>{displayValue(userData.email)}</span>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Rôle :</strong> <span>{displayValue(userData.role)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
