  import React, { useEffect, useState } from 'react';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import ReactPhoneInput from 'react-phone-input-2';
  import 'react-phone-input-2/lib/style.css';
  import { Spinner } from 'react-bootstrap';
  import '../styles/AccountPage.css';

  import Profile from "../components/Profile";

  const AccountPage = () => {

      return (
      <div className="container mt-2 mb-2 flex-grow-1">
      <div className="row justify-content-center">
        {/* Première card */}
        <Profile />
        {/* Deuxiéme card */}
      <div className="container mt-5 mb-4 flex-grow-1">
      <div className="row justify-content-center">
      <div className="col-12 col-md-8">
      <div className="card shadow-lg rounded-lg border-0">
      <div className="card-body p-4">
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      );
    };

    export default AccountPage;
