import React, { useState } from 'react';
import profile from '../assets/images/profile.jpg';

const ProfilePicture = () => {
  const [profileImage, setProfileImage] = useState(profile); // URL de l'image par défaut
  const [hover, setHover] = useState(false); // État pour détecter le survol

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result); // Met à jour l'image avec le contenu du fichier
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className="profile-avatar"
      style={{
        width: '120px',
        height: '120px',
        position: 'absolute',
        top: '255px',
        left: '15px',
        transform: 'translateY(-50%)',
        borderRadius: hover ? '50%' : '0%', // Bordure arrondie au survol
        border: hover ? '3px solid blue' : 'none', // Bordure bleue au survol
        overflow: 'hidden', // Assure que l'image reste dans le cercle
        transition: 'border-radius 0.3s, border 0.3s', // Transition fluide pour la bordure et le border-radius
        cursor: 'pointer', // Indique que l'élément est cliquable
      }}
      onMouseEnter={() => setHover(true)} // Active le survol
      onMouseLeave={() => setHover(false)} // Désactive le survol
    >
      <label htmlFor="profile-upload" style={{ width: '100%', height: '100%', display: 'block' }}>
        <img
          src={profileImage}
          alt="Avatar"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
            transform: hover ? 'scale(1.1)' : 'scale(1)', // Agrandit l'image au survol
            transition: 'transform 0.3s ease-in-out', // Animation fluide pour l'agrandissement
          }}
        />
      </label>
      {/* Champ pour choisir un fichier */}
      <input
        id="profile-upload"
        type="file"
        accept="image/*"
        style={{ display: 'none' }} // Caché, cliquable via le label
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ProfilePicture;
