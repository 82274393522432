import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [motDePasse, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const dataToSend = {
        email: email.trim(),
        motDePasse: motDePasse.trim(),
      };

      const response = await axios.post('https://dev.mysheduloom.fr/api/login', dataToSend, { headers: { 'Content-Type': 'application/json' } });

      if (response.data && response.data.data?.id && response.data.redirect) {
        // Enregistrer l'ID utilisateur dans le localStorage
        const userId = response.data.data.id;
        console.log('ID utilisateur enregistré :', userId);
        localStorage.setItem('userId', userId);

        // Afficher un message de succès
        setSuccessMessage(response.data.message || 'Connexion réussie !');
        console.log('Redirection vers :', response.data.redirect);

        // Redirection vers la page spécifiée
        navigate(response.data.redirect);
      } else {
        setErrorMessage('Données de connexion incomplètes. Veuillez réessayer.');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Erreur lors de la connexion. Veuillez réessayer.');
      } else if (error.request) {
        setErrorMessage('Le serveur est injoignable. Veuillez réessayer plus tard.');
      } else {
        setErrorMessage('Une erreur interne est survenue. Veuillez réessayer.');
      }
    }
  };

  const handleGoHome = () => {
    navigate('/'); // Redirige vers la page d'accueil
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#f4f4f9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '60px',
      }}
    >
      <div
        className="card shadow-lg"
        style={{
          maxWidth: '500px',
          width: '100%',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <h2 className="text-center mb-4">Connexion à Votre Compte</h2>
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group mb-3">
            <label htmlFor="email">Adresse Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Mot de Passe</label>
            <input
              type="password"
              id="password"
              value={motDePasse}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
          {successMessage && <p className="text-success text-center">{successMessage}</p>}
          <button type="submit" className="btn btn-primary w-100 py-2 mt-3">
            Se Connecter
          </button>
          {/* Nouveau bouton pour retourner à la page d'accueil */}
          <button
            type="button"
            onClick={handleGoHome}
            className="btn btn-danger w-100 py-2 mt-3"
          >
            Retour à l'Accueil
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
