import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Spinner } from 'react-bootstrap';
import '../styles/AccountPage.css';



import ProfilePicture from "../components/ProfilePicture";
import BackgroundPicture from "../components/BackgroundPicture";
import UserInfo from "../components/UserInfo";


const Profile = () => {
  return (
    <>
    <div className="col-12 col-md-8 mt-4 mb-3">
        <div className="card shadow-lg rounded-lg border-0">
          {/* Card principale */}
          <BackgroundPicture />

          {/* Photo de profil */}
          <ProfilePicture />

          {/* Bouton "Éditer" qui se transforme en "Valider" ou "Annuler" */}
          {/* Informations de l'utilisateur */}
          <UserInfo />
        </div>
    </div>
    </>
  );
};

export default Profile;