import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ActivationCodePage = () => {
  const [email, setEmail] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isResendLoading, setIsResendLoading] = useState(false);
  const navigate = useNavigate();

  // Fonction pour activer le compte
  const handleActivate = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    console.log('Tentative d\'activation pour:', email);

    try {
      const dataToSend = {
        email: email.trim(),
        activationCode: activationCode.trim(),
      };

      console.log('Données envoyées:', dataToSend);

      const response = await axios.post(
        'https://dev.mysheduloom.fr/api/activate',
        dataToSend,
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log('Réponse du serveur:', response);

      if (response.data && response.data.message) {
        setSuccessMessage(response.data.message || 'Code d’activation validé avec succès !');
        setTimeout(() => {
          navigate('/login'); // Redirige vers la page de connexion
        }, 2000);
      } else {
        setErrorMessage('Échec de la validation du code d’activation. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de la demande:', error);

      if (error.response) {
        setErrorMessage(error.response.data.message || 'Erreur lors de la validation. Veuillez réessayer.');
      } else if (error.request) {
        setErrorMessage('Le serveur est injoignable. Veuillez réessayer plus tard.');
      } else {
        setErrorMessage('Une erreur interne est survenue. Veuillez réessayer.');
      }
    }
  };

  // Fonction pour demander un nouveau code
  const handleResendCode = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsResendLoading(true);
    console.log('Demande de renvoi du code pour:', email);

    try {
      const response = await axios.post(
        'https://dev.mysheduloom.fr/api/resendActivationCode',
        { email: email.trim() },
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log('Réponse du serveur pour renvoi:', response);

      if (response.data && response.data.message) {
        setSuccessMessage(response.data.message || 'Un nouveau code d’activation a été envoyé.');
      } else {
        setErrorMessage('Erreur lors de la demande de nouveau code. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de la demande de renvoi du code:', error);

      if (error.response) {
        setErrorMessage(error.response.data.message || 'Erreur lors de la demande. Veuillez réessayer.');
      } else if (error.request) {
        setErrorMessage('Le serveur est injoignable. Veuillez réessayer plus tard.');
      } else {
        setErrorMessage('Une erreur interne est survenue. Veuillez réessayer.');
      }
    } finally {
      setIsResendLoading(false);
    }
  };

  // Fonction pour retourner à la page d'accueil
  const handleGoHome = () => {
    navigate('/'); // Redirige vers la page d'accueil
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#f4f4f9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '60px',
      }}
    >
      <div
        className="card shadow-lg"
        style={{
          maxWidth: '500px',
          width: '100%',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <h2 className="text-center mb-4">Activation du Compte</h2>
        <form onSubmit={handleActivate} className="activation-code-form">
          <div className="form-group mb-3">
            <label htmlFor="email">Adresse Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="activationCode">Code d’Activation</label>
            <input
              type="text"
              id="activationCode"
              value={activationCode}
              onChange={(e) => setActivationCode(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
          {successMessage && <p className="text-success text-center">{successMessage}</p>}
          <button type="submit" className="btn btn-primary w-100 py-2 mt-3">
            Activer le Compte
          </button>
          <button
            type="button"
            onClick={handleResendCode}
            className="btn btn-secondary w-100 py-2 mt-3"
            disabled={isResendLoading || !email.trim()}
          >
            {isResendLoading ? 'Envoi en cours...' : 'Renvoyer le Code'}
          </button>
          <button
            type="button"
            onClick={handleGoHome}
            className="btn btn-danger w-100 py-2 mt-3"
          >
            Retour à l'Accueil
          </button>
        </form>
      </div>
    </div>
  );
};

export default ActivationCodePage;
