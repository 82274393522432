import React, { useState, useEffect } from 'react';
import wallpaper from '../assets/images/wallpaper.png'; // Image par défaut
import axios from 'axios';

const BackgroundPicture = () => {
  const [background, setBackground] = useState(wallpaper); // Image par défaut
  const [hover, setHover] = useState(false); // Survol de l'image

  useEffect(() => {
    const fetchBackground = async () => {
      console.log("[INFO] Début de la récupération de l'image de fond.");

      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          console.error("[ERREUR] userId introuvable dans le localStorage.");
          setBackground(wallpaper);
          return;
        }

        const response = await axios.get(`https://dev.mysheduloom.fr/api/getBackgroundImage`, {
          params: { userId },
        });

        if (response.status === 200) {
          const backgroundImage = response.data?.backgroundImage;
          if (backgroundImage) {
            const fullPath = `https://dev.mysheduloom.fr${backgroundImage}`;
            const img = new Image();
            img.onload = () => setBackground(fullPath);
            img.onerror = () => setBackground(wallpaper);
            img.src = fullPath;
          } else {
            setBackground(wallpaper);
          }
        } else {
          setBackground(wallpaper);
        }
      } catch (error) {
        setBackground(wallpaper);
      }
    };

    fetchBackground();
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('backgroundImage', file);

      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("[ERREUR] userId introuvable dans le localStorage.");
        return;
      }
      formData.append('userId', userId);

      const response = await axios.post(
        'https://dev.mysheduloom.fr/api/uploadBackgroundImage',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        const newImagePath = response.data?.backgroundImage;
        if (newImagePath) {
          const fullPath = `https://dev.mysheduloom.fr${newImagePath}`;
          setBackground(fullPath);
        } else {
          console.warn("[WARN] La réponse ne contient pas de chemin pour l'image.");
        }
      } else {
        console.error(`[ERREUR] Échec de l'upload avec le statut ${response.status}`);
      }
    } catch (error) {
      console.error("[ERREUR] Une erreur est survenue lors de l'upload de l'image :", error);
    }
  };

  return (
    <div
      className="profile-container d-flex align-items-center justify-content-between position-relative"
      style={{
        borderRadius: hover ? '15px' : '0px',
        border: hover ? '3px solid blue' : 'none',
        overflow: 'hidden',
        transition: 'border-radius 0.3s, border 0.3s',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <label htmlFor="background-upload" style={{ width: '100%', height: '100%', display: 'block' }}>
        <img
          src={background}
          alt="Fond d'écran"
          className="profile-background"
          style={{
            width: '100%',
            height: '250px',
            objectFit: 'cover',
            transform: hover ? 'scale(1.1)' : 'scale(1)',
            transition: 'transform 0.3s ease-in-out',
          }}
        />
      </label>
      <input
        id="background-upload"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default BackgroundPicture;
